import { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import Select, { ActionMeta, ContainerProps, CSSObjectWithLabel, GroupBase, MultiValue, OnChangeValue, OptionProps, Props, StylesConfig } from 'react-select';

type MultiSelectIsMulti = true;

export type MultiSelectOption = {
    label: string;
    value: number;
}

type MultiSelectProps<Option = MultiSelectOption, IsMulti extends boolean = MultiSelectIsMulti, Group extends GroupBase<Option> = GroupBase<Option>> = Props<Option, IsMulti, Group> &
    WithTranslation & {
    allowSelectAll?: boolean;
    showWarningBorder?: boolean;
    value?: MultiValue<MultiSelectOption>;
}

type MultiSelectOptionProps<Option = MultiSelectOption, IsMulti extends boolean = MultiSelectIsMulti, Group extends GroupBase<Option> = GroupBase<Option>> = OptionProps<Option, IsMulti, Group>;

type MultiSelectStylesConfig<Option = MultiSelectOption, IsMulti extends boolean = MultiSelectIsMulti, Group extends GroupBase<Option> = GroupBase<Option>> = StylesConfig<Option, IsMulti, Group>;

type MultiSelectOnChangeValue<Option = MultiSelectOption, IsMulti extends boolean = MultiSelectIsMulti> = OnChangeValue<Option, IsMulti>;

type MultiSelectContainerProps<Option = MultiSelectOption, IsMulti extends boolean = MultiSelectIsMulti, Group extends GroupBase<Option> = GroupBase<Option>> = ContainerProps<Option, IsMulti, Group>

class MultiSelect extends Component<MultiSelectProps> {

    private readonly selectAllOption = {
        label: this.props.t('components.Form.Select all'),
        value: -1,
    } as MultiSelectOption;

    private readonly customStyle: MultiSelectStylesConfig = {
        control: (base: CSSObjectWithLabel, option: MultiSelectContainerProps)=> ({
            ...base,
            minHeight: '3.625rem',
        }),
        option: (base: CSSObjectWithLabel, option: MultiSelectOptionProps)=> ({
            ...base,
            borderBottom: this.selectBackground(option)
        }),
    };

    selectBackground = (option: MultiSelectOptionProps) => (
        option.data.value === this.selectAllOption.value ? '1px solid #666' : '');

    onChange = (newValue: MultiSelectOnChangeValue, actionMeta: ActionMeta<MultiSelectOption>) => {
        const { onChange, options = [] } = this.props;

        if (onChange) {
            if (newValue.findIndex((option) => option.value === this.selectAllOption.value) !== -1) {
                onChange(options as MultiSelectOnChangeValue, actionMeta);
                return;
            }

            onChange(newValue, actionMeta);
        }
    };

    public render() {
        const {showWarningBorder = false, allowSelectAll = false, options = [], onChange, value, ...rest} = this.props;

        const opts = Array.from(options);
        if (allowSelectAll && (!value || options.length > value.length)) {
            opts.unshift(this.selectAllOption);
        }

        return (
            <Select
                styles={this.customStyle}
                isMulti
                onChange={this.onChange}
                options={opts}
                value={value}
                className={showWarningBorder ? 'blueBorder' : 'noBorder'}
                {...rest} />
        );
    }
}

const MultiSelectWithTranslation = withTranslation()(MultiSelect);

export { MultiSelectWithTranslation as MultiSelect };