import { Observable } from '@babylonjs/core/Misc/observable';
import { ISharedAccessSignatureOnFailure, ISharedAccessSignatureOnProgress } from '../../azure/SharedAccessSignature';
import { File as CoreFile } from '../../core/File';
import { ID } from '../../utils';
import FileEventManagement from '../FileEventManagement';
import { FileUploadProgressController } from '../FileUploadProgress';
import { File } from './File';
import { Medialink } from './Medialink';

type FileUploadAdapterReference = ComponentReference | Record<string, any> | undefined;

type FileUploadAdapterIdData = {
    id: string;
};

type FileUploadAdapterMedialinkData = {
    medialink: Medialink;
};

type FileUploadAdapterOnStarted = {
    reference: FileUploadAdapterReference;
    data: FileUploadAdapterIdData | FileUploadAdapterMedialinkData;
}

type FileUploadAdapterOnProgressData = FileUploadAdapterIdData & {
    label: string;
    percentage: number;
    loaded: number;
    total: number;
    abortController: AbortController;
}

export type FileUploadAdapterOnProgress = {
    reference: FileUploadAdapterReference;
    data: FileUploadAdapterOnProgressData;
}

export type FileUploadAdapterOnCancelled = {
    reference: FileUploadAdapterReference;
    data: FileUploadAdapterIdData;
}

export type FileUploadAdapterOnFailureData = FileUploadAdapterIdData & {
    innerFailure?: ISharedAccessSignatureOnFailure;
};

export type FileUploadAdapterOnFailure = {
    reference: FileUploadAdapterReference;
    data: FileUploadAdapterOnFailureData;
}

export type FileUploadAdapterOnCompleted = {
    reference: FileUploadAdapterReference;
    data: FileUploadAdapterIdData;
}

export type AddFileOnProgressHandler = (data: ISharedAccessSignatureOnProgress) => void;

export type AddFileOnFailureHandler = (data: ISharedAccessSignatureOnFailure) => void;

type AddFileHandler<T = any> = (file: File, abortSignal: AbortSignal, onProgress?: AddFileOnProgressHandler, onFailure?: AddFileOnFailureHandler) => Promise<T>;
type AddMedialinkHandler<T = any> = (medialink: Medialink) => Promise<T>;
type DeleteFileHandler = (file: CoreFile) => Promise<CoreFile>;

class FileUploadAdapter<T = any> {

    static readonly OnStarted = new Observable<FileUploadAdapterOnStarted>();
    static readonly OnProgress = new Observable<FileUploadAdapterOnProgress>();
    static readonly OnCancelled = new Observable<FileUploadAdapterOnCancelled>();
    static readonly OnFailure = new Observable<FileUploadAdapterOnFailure>();
    static readonly OnCompleted = new Observable<FileUploadAdapterOnCompleted>();

    public fileUploadProgressController: FileUploadProgressController | undefined;
    public reference: FileUploadAdapterReference;

    #_addFile: AddFileHandler<T>;
    #_addMedialink: AddMedialinkHandler<T> | undefined;
    #_deleteFile: DeleteFileHandler | undefined;

    constructor(addFile: AddFileHandler<T>, addMedialink?: AddMedialinkHandler<T>, deleteFile?: DeleteFileHandler) {
        this.#_addFile = addFile;
        this.#_addMedialink = addMedialink;
        this.#_deleteFile = deleteFile;
    }

    addFile = (file: File) : Promise<T | Error> => {
        if (!(file instanceof File)) {
            throw new TypeError('File parameters is not an instance of FileUpload.File');
        }

        const id = ID();
        const abortController = new AbortController();
        this.fileUploadProgressController?.add(id, file.fileName, abortController);
        const { signal: abortSignal } = abortController;

        abortSignal.addEventListener('abort', () => {
            this.fileUploadProgressController?.cancel(id);
            FileUploadAdapter.OnCancelled.notifyObservers({
                reference: this.reference,
                data: { id },
            });
        });

        const onProgress = ({ loaded, total } : ISharedAccessSignatureOnProgress) => {
            const percentage = Math.floor((loaded / total) * 100);

            this.fileUploadProgressController?.update(id, percentage, loaded, total);

            FileUploadAdapter.OnProgress.notifyObservers({
                reference: this.reference,
                data: {
                    id, label: file.fileName, percentage, loaded, total, abortController
                },
            });
        };

        const onFailure = (data: ISharedAccessSignatureOnFailure) => {
            this.fileUploadProgressController?.failure(id);

            FileUploadAdapter.OnFailure.notifyObservers({
                reference: this.reference,
                data: { id, innerFailure: data },
            });
        };

        FileUploadAdapter.OnStarted.notifyObservers({
            reference: this.reference,
            data: { id },
        });

        return this.#_addFile(file, abortSignal, onProgress, onFailure).then((response: T) => {
            setTimeout(() => this.fileUploadProgressController?.remove(id), 5000);

            FileUploadAdapter.OnCompleted.notifyObservers({
                reference: this.reference,
                data: { id },
            });

            FileEventManagement.OnFileAdded.notifyObservers({
                reference: this.reference,
                data: response,
            });

            return response;
        }).catch((error: Error) => {
            if (error.name === 'AbortError') {
                setTimeout(() => this.fileUploadProgressController?.remove(id), 5000);
                return error;
            }

            if (error.name === 'Conflict' || error.message.indexOf('already exists') !== -1) {
                FileUploadAdapter.OnFailure.notifyObservers({
                    reference: this.reference,
                    data: { id },
                });
            }

            this.fileUploadProgressController?.remove(id);

            throw error;
        });
    };

    addMediaLink = (medialink: Medialink) : Promise<T | Error> => {
        if (!this.#_addMedialink) {
            throw new Error('Add medialink method is not defined');
        }

        if (!(medialink instanceof Medialink)) {
            throw new TypeError('File parameters is not an instance of FileUpload.MediaLink');
        }

        FileUploadAdapter.OnStarted.notifyObservers({
            reference: this.reference,
            data: { medialink: medialink },
        });

        return this.#_addMedialink(medialink).then((response: T) => {
            FileEventManagement.OnFileAdded.notifyObservers({
                reference: this.reference,
                data: response,
            });

            return response;
        });
    }

    deleteFile = (file: CoreFile) : Promise<CoreFile> => {
        if (!this.#_deleteFile) {
            throw new Error('Delete method is not defined');
        }

        if (!(file instanceof CoreFile)) {
            throw new TypeError('File parameters is not an instance of Core.File');
        }

        return this.#_deleteFile(file).then(() => {
            FileEventManagement.OnFileDeleted.notifyObservers({
                reference: this.reference,
                data: file,
            });

            return file;
        });
    }
}

export { FileUploadAdapter };
