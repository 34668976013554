import { forwardRef, InputHTMLAttributes, ReactNode, Ref, useRef } from 'react';
import { ID } from '../../utils/ID';

interface InputGroupProps extends InputHTMLAttributes<HTMLInputElement> {
    label: string;
    children?: ReactNode,
}

const InputGroup = forwardRef((props: Readonly<InputGroupProps>, ref: Ref<HTMLInputElement>) => {
    const {
        label,
        disabled: isDisabled,
        className,
        children,
        required = false,
        id: inputId,
        ...rest
    } = props;

    // const labelClass = 'col-form-label col-form-label-lg';
    const inputClass = 'form-control form-control-lg';

    const id = useRef(inputId ?? ID());

    const r = required ? '*' : '';

    return (
        <div className="form-group">
            <label htmlFor={id.current} >{label}{r}: </label>

            <input
                ref={ref}
                className={`${className} ${inputClass} ${isDisabled && ' form-control-disabled' }`}
                id={id.current}
                disabled={isDisabled}
                required={required}
                {...rest} />
            {children}
        </div>
    );
});

InputGroup.displayName = 'InputGroup';

export { InputGroup };